export default () => {
  if (process.server) return

  // missing forEach on NodeList for IE11
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach
  }

  if ('IntersectionObserver' in window) return

  return new Promise((resolve, reject) => {
    import(/* webpackChunkName:'package/intersection-observer' */ 'intersection-observer')
      .then(resolve)
      .catch(reject)
  })
}
