import { stringifyQuery } from '@/assets/js/globalFunction'

export default async function({ $auth, $config, app }, inject) {
  if (!$auth) return

  $auth.onRedirect((to, from) => {
    // prevent double redirect
    if (to === $auth.options.redirect.home) {
      window.location.href = `${app.$urls.airpazUrl}/${app.i18n.locale}`
    }

    if (typeof from === 'string') {
      if (from.includes(`/${app.i18n.locale}/order`)) return '/account/order'
      else if (from.includes(`/${app.i18n.locale}/retrieve`)) return '/'
    }
  })

  const oauth2 = {
    loginWith(provider) {
      const oath2ProviderOptions = {
        facebook: {
          endpoint: 'https://facebook.com/v13.0/dialog/oauth',
          client_id: $config.facebookClientId,
          scope: ['public_profile', 'email']
        },
        google: {
          endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
          client_id: $config.googleClientId,
          scope: [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
            'openid'
          ],
          code_challenge_method: '',
          response_type: 'id_token token'
        }
      }

      if (!oath2ProviderOptions[provider]) {
        return Promise.reject('OAuth provider not supported')
      }

      const { endpoint, ...options } = oath2ProviderOptions[provider]

      const opts = {
        protocol: 'oauth2',
        response_type: 'token',
        access_type: '',
        redirect_uri: window.location.origin + '/callback',
        code_challenge_method: 'implicit',
        state: randomString(),
        nonce: randomString(),
        ...options,
        scope: options.scope.join(' ')
      }

      $auth.$storage.setUniversal('oauth2_provider', provider)
      $auth.$storage.setUniversal(provider + '.state', opts.state)

      const oauthUrl = endpoint + '?' + stringifyQuery(opts)

      window.location.replace(oauthUrl)
    }
  }

  inject('oauth2', oauth2)
}

const randomString = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
