import CustomFormatter from '~/assets/js/i18nFormatter'
export default ({ app }) => {
  app.i18n.fetchChoice = (message, choice) => {
    if (!message && typeof message !== 'string') {
      return null
    }
    if (typeof message == 'string') return message
    const choices = message

    choice = app.i18n.getChoiceIndex(choice, choices.length)

    if (choices.length == 1) {
      return choices[0]
    }

    if (!choices[choice]) {
      return message
    }
    return choices[choice].trim()
  }
  app.i18n.formatter = new CustomFormatter()
}
