import isEmpty from 'lodash/isEmpty'

export default function(ctx) {
  const { route, app, isDev } = ctx

  const utmParams = {}

  Object.entries(route.query).forEach(([key, value]) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value
    }
  })
  if (!isEmpty(utmParams)) {
    const bufferUtm = Buffer.from(JSON.stringify(utmParams)).toString('base64')

    app.$cookies.set('utm_traffic', bufferUtm, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      maxAge: 24 * 60 * 60
    })

    if (process.client) {
      window.sessionStorage.setItem('utm_traffic', bufferUtm)
    }
  }
}
