import Vue from 'vue'
import dayjs from 'dayjs'
import { LOCALE_DATE_FORMAT } from '@airpaz/apz-js'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { get, toUpper, isEmpty } from 'lodash'
import {
  getFlightCode,
  trim,
  getBufferString,
  extractOrderFlightData,
  stringifyQuery
} from '@/assets/js/globalFunction'
import errorPoKeys from '@/config/error-po-keys'
import isMobileDevice from 'ismobilejs'

const DAYJS_LOCALE = {
  en: require('@airpaz/apz-js/dist/dayjs-locales/en').default,
  id: require('@airpaz/apz-js/dist/dayjs-locales/id').default,
  ja: require('@airpaz/apz-js/dist/dayjs-locales/ja').default,
  ko: require('@airpaz/apz-js/dist/dayjs-locales/ko').default,
  ms: require('@airpaz/apz-js/dist/dayjs-locales/ms').default,
  th: require('@airpaz/apz-js/dist/dayjs-locales/th').default,
  tl: require('@airpaz/apz-js/dist/dayjs-locales/tl').default,
  vi: require('@airpaz/apz-js/dist/dayjs-locales/vi').default,
  'zh-tw': require('@airpaz/apz-js/dist/dayjs-locales/zh-tw').default,
  zh: require('@airpaz/apz-js/dist/dayjs-locales/zh').default,
  hi: require('@airpaz/apz-js/dist/dayjs-locales/hi').default,
  it: require('@airpaz/apz-js/dist/dayjs-locales/it').default,
  fr: require('@airpaz/apz-js/dist/dayjs-locales/fr').default,
  de: require('@airpaz/apz-js/dist/dayjs-locales/de').default,
  es: require('@airpaz/apz-js/dist/dayjs-locales/es').default,
  ru: require('@airpaz/apz-js/dist/dayjs-locales/ru').default,
  bn: require('@airpaz/apz-js/dist/dayjs-locales/bn').default,
  pt: require('@airpaz/apz-js/dist/dayjs-locales/pt').default,
  km: require('@airpaz/apz-js/dist/dayjs-locales/km').default,
  pl: require('@airpaz/apz-js/dist/dayjs-locales/pl').default,
  nl: require('@airpaz/apz-js/dist/dayjs-locales/nl').default,
  tr: require('@airpaz/apz-js/dist/dayjs-locales/tr').default,
  sv: require('@airpaz/apz-js/dist/dayjs-locales/sv').default,
  cs: require('@airpaz/apz-js/dist/dayjs-locales/cs').default,
  nb: require('@airpaz/apz-js/dist/dayjs-locales/nb').default,
  ro: require('@airpaz/apz-js/dist/dayjs-locales/ro').default,
  el: require('@airpaz/apz-js/dist/dayjs-locales/el').default,
  da: require('@airpaz/apz-js/dist/dayjs-locales/da').default,
  uk: require('@airpaz/apz-js/dist/dayjs-locales/uk').default,
  hr: require('@airpaz/apz-js/dist/dayjs-locales/hr').default,
  ca: require('@airpaz/apz-js/dist/dayjs-locales/ca').default,
  hu: require('@airpaz/apz-js/dist/dayjs-locales/hu').default,
  sk: require('@airpaz/apz-js/dist/dayjs-locales/sk').default,
  fi: require('@airpaz/apz-js/dist/dayjs-locales/fi').default,
  sl: require('@airpaz/apz-js/dist/dayjs-locales/sl').default,
  bg: require('@airpaz/apz-js/dist/dayjs-locales/bg').default,
  sr: require('@airpaz/apz-js/dist/dayjs-locales/sr').default
}

dayjs.extend(LocalizedFormat)

const capitalize = function(text) {
  let result = ''
  if (text) {
    const textArr = text.split(' ')
    const textArrCapitalized = textArr.map(word => {
      return word ? `${word[0].toUpperCase()}${word.slice(1)}` : ''
    })
    result = textArrCapitalized.join(' ')
  }
  return result
}

const resizeImage = function(url, width, quality = 90) {
  if (!url) return

  const isIllustration = url.match(/illustration/gi)
  const urlArr = url.match(/[^\/]+(?:\/\/[^\/]*)*/gi)

  if (!urlArr[2] || typeof +urlArr[2] !== 'number' || isIllustration) return url

  const path = urlArr[0]
  const newPath = `${path}/cdn-cgi/image/width=${width},quality=${quality}`
  const newImgUrl = url.replace(path, newPath)

  return newImgUrl
}
// Inject locale from store
const displayDate = function(value, to, from) {
  if (!value) return ''
  const locale = this.$store.state.config.locale
  let date = dayjs(value)
  if (from) {
    date = dayjs(value, from)
    if (from === 'unix') {
      date = dayjs.unix(value)
    }
  }

  const format =
    DAYJS_LOCALE[locale.toLowerCase()]?.formats[to] ??
    to ??
    LOCALE_DATE_FORMAT[locale.toLowerCase()]
  const actualDate = date
    .locale(DAYJS_LOCALE[locale.toLowerCase()])
    .format(format)
  return actualDate
}
// Inject locale from store
const displayName = function(nameObj, showTitle = false, lang) {
  /**
   * Take an object containing firstName, lastName and title and then return a display name.
   *
   * @param {Object} nameObj Object containing name information.
   * @param {boolean} [showTitle=false] Optional. Decide if the title is show, always false if no title is given in nameObj.
   * @param {string} [lang] Optional. Decide the position of the name information according to the language.
   *
   * @return {string} Display name.
   */
  let locale = this.$store.state.config.locale
  if (lang) locale = lang
  const templateWTitle = {
    en: 'title lastName, firstName',
    id: 'title firstName lastName'
  }
  const templateWOTitle = {
    en: 'lastName, firstName',
    id: 'firstName lastName'
  }
  if (Object.keys(templateWTitle).indexOf(locale) === -1) locale = 'en'
  if (typeof nameObj === 'object' && nameObj !== null) {
    let { firstName, lastName, title } = nameObj

    // Must contain firstName and lastName
    if (!firstName || !lastName) return

    firstName = capitalize(firstName)
    lastName = capitalize(lastName)
    title = capitalize(title)

    if (!title) showTitle = false
    else if (title.toLowerCase() !== 'adult') {
      title =
        title.toLowerCase() === 'infant' || title.toLowerCase() === 'child'
          ? this.$t(`global.${title.toLowerCase()}`)[0]
          : this.$t(`global.${title.toLowerCase()}`)
    }

    const sourceTemplate = showTitle
      ? templateWTitle[locale]
      : templateWOTitle[locale]

    let result = sourceTemplate
      .replace('firstName', firstName)
      .replace('lastName', lastName)

    if (showTitle) result = result.replace('title', title)
    return result
  }
}

const displayCurrency = function(originValue, curr, symbol = true) {
  let value = originValue
  let { listCurrency, currency } = this.$store.state.config
  const listExcludedCurrency = ['BND', 'MYR']
  if (curr) {
    currency = curr
  }
  const currFormat = listCurrency.find(val => val.currency === currency)
  //in case there is no format in currency
  if (!currFormat) return value
  if (!!currFormat && currFormat.precision > 0)
    if (listExcludedCurrency.indexOf(currency) !== -1) {
      value = Number(Number(value).toFixed(currFormat.precision))
      value = value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, currFormat.thousand)
    } else
      value = Number(
        Number(value).toFixed(currFormat.precision)
      ).toLocaleString(currency.substr(0, 2))
  else
    value = Number(Number(value).toFixed(currFormat.precision))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, currFormat.thousand)

  let currencyStr = value

  if (symbol) {
    if (originValue < 0) {
      currencyStr = `- ${currFormat.symbol} ${value
        .toString()
        .replace('-', '')}`
    } else {
      currencyStr = `${currFormat.symbol} ${value}`
    }
  }

  return currencyStr
}

const displayCityName = function(code) {
  this.$store.dispatch('master/fetchAirportList')
  const { cityName } =
    this.$store.getters['master/getAirportDetail'](code) || {}
  return cityName || code
}
const displayAirportName = function(code) {
  this.$store.dispatch('master/fetchAirportList')
  const { name } = this.$store.getters['master/getAirportDetail'](code) || {}
  return name || code
}

const validateCountryCode = function(code) {
  return typeof code === 'string' && code.length === 2
}

const displayCountryName = function(code) {
  this.$store.dispatch('master/fetchCountryList')
  const { name } = this.$store.getters['master/getCountryDetail'](code) || {}
  // if (!name)
  //   this.$sentry &&
  //     this.$sentry.captureException(`COUNTRY_NAME_NOT_FOUND => (${code})`)
  return name || code
}

const displayAirlinesName = function(code) {
  this.$store.dispatch('master/fetchAirlineList')
  const { name } = this.$store.getters['master/getAirlineDetail'](code) || {}

  return name || code
}

const displayAirlinesLogo = function(value) {
  return `${this.$config.airlineLogoUrl}/${getFlightCode(value)}.png`
}

const getBlazePath = function(path, query, hostName = true) {
  const locale = this.$i18n.locale
  if (!path.startsWith('/')) {
    path = '/' + path
  }

  if (!path.startsWith('/' + locale)) {
    path = `/${locale}${path}`
  }

  path = path.replace(/\/+$/, '')

  if (query) {
    path += '?' + stringifyQuery(query)
  }

  return hostName ? `${this.$urls.airpazUrl}${path}` : `${path}`
}

export const functionHelper = {
  filters: {
    trim,
    capitalize,
    resizeImage
  },
  computed: {
    showButtonSwitchUI() {
      return !!this.$cookies.get('force_beta')
    },
    isAirpazApp() {
      if (!process.client) return
      const userAgent = process.server
        ? this.$nuxt.req?.headers['user-agent']
        : navigator.userAgent
      return /apzAndroid|apzIos/gi.test(userAgent)
    },
    isSafariIOS() {
      if (!process.client) return

      return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        !window.MSStream
      )
    },
    platform() {
      const isMobileObj = isMobileDevice(
        process.server
          ? this.$nuxt.req?.headers['user-agent']
          : window.navigator
      )
      const isMobile = isMobileObj.any
      let platform = 'web'

      if (isMobileObj.apple.device) {
        platform = 'ios'
      } else if (
        isMobileObj.android.device ||
        isMobileObj.amazon.device ||
        isMobileObj.windows.device
      ) {
        platform = 'android'
      }
      return isMobile ? platform : 'web'
    },
    isAgentMember() {
      return get(this.$auth, 'user.type', null) === 'AGENT'
    }
  },
  methods: {
    trim,
    capitalize,
    validateCountryCode,
    getBlazePath,
    displayDate,
    displayName,
    displayCurrency,
    displayCityName,
    displayCountryName,
    displayAirportName,
    displayAirlinesName,
    displayAirlinesLogo,
    normalizeDateTimezone(date, providedTimezone) {
      return dayjs(date)
        .subtract(providedTimezone ?? 0, 'second')
        .add(dayjs().utcOffset() * 60, 'second')
    },

    getFlightDayOffset(flight) {
      const { depDateTime = '', arrDateTime = '' } = Object.assign({}, flight)

      if (typeof depDateTime !== 'string' || typeof arrDateTime !== 'string')
        return 0

      const normalizedDepDate = dayjs(depDateTime.split(' ')[0])
      const normalizedArrDate = dayjs(arrDateTime.split(' ')[0])
      const dayOffset = normalizedArrDate.diff(normalizedDepDate, 'day', true)

      return dayOffset
    },

    getTotalNights(from, to) {
      const totalNight = dayjs(to).diff(from, 'day')
      return +totalNight
    },

    getDefaultMeta() {
      const host = process.server
        ? this.$ssrContext.req.headers.host
        : window.location.host
      return {
        title: `${this.$t('feature.metatitle')}`,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$t('global.gdesc')
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.$t('feature.metatitle')
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.$t('global.gdesc')
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: 'https://cdn.airpaz.com/social-logo.png'
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: `https://${host}${this.$route.path}`
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: 'Airpaz.com'
          },
          {
            hid: 'fb:app_id',
            property: 'fb:app_id',
            content: '352869744803635'
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: this.$t('feature.metatitle')
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: this.$t('global.gdesc')
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: 'https://cdn.airpaz.com/logo.png'
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary'
          },
          {
            hid: 'twitter:url',
            name: 'twitter:url',
            content: `https://${host}${this.$route.path}`
          },
          { hid: 'twitter:site', name: 'twitter:site', content: '@airpaz' },
          {
            hid: 'twitter:creator',
            name: 'twitter:creator',
            content: '@airpaz'
          }
        ]
      }
    },
    getRoomNight(room, night) {
      if (!room) return ''
      const stringNight =
        night == 1
          ? this.$tc('hotel.nnight', 1)
          : `${night} ${this.$tc('hotel.night', 2)}`
      return `${stringNight} ${room} ${this.$tc('hotel.room', room)}`
    },
    resizeImage,
    routerTransformObjectLocation(parObjectLocation) {
      const objectLocation = { ...parObjectLocation }
      if (objectLocation && objectLocation.name) {
        objectLocation.path = this.localePath(objectLocation)
      }
      return objectLocation
    },
    async memberLogout() {
      await this.$auth.logout()

      window.google?.accounts.id.disableAutoSelect()

      this.$toast.open({
        type: 'success',
        message: this.$t('global.logoutsuccess')
      })
    },

    postMessageRN(key, payload) {
      if (
        this.isAirpazApp &&
        typeof window.ReactNativeWebView !== 'undefined'
      ) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ key, payload }))
      }
    },

    getFlightKeys(flightData) {
      const keys = []
      const isOneStopFlight = flightData.type === 'onestop'
      if (isOneStopFlight) {
        const key1 = `${flightData.depAirport}${flightData.traAirport}`
        const key2 = `${flightData.traAirport}${flightData.arrAirport}`
        keys.push(key1, key2)
      } else {
        const key = `${flightData.depAirport}${flightData.arrAirport}`
        keys.push(key)
      }
      return keys
    },
    getBaggageList(baggageOption, selectedFlight) {
      const baggageList = {}
      Object.keys(baggageOption).forEach(selectionType => {
        if (baggageOption[selectionType] && selectedFlight[selectionType]) {
          const flightKeys = this.getFlightKeys(selectedFlight[selectionType])
          baggageOption[selectionType].forEach((flightTypeBagList, index) => {
            if (flightTypeBagList.length > 0) {
              baggageList[flightKeys[index]] = flightTypeBagList
            }
          })
        }
      })
      return baggageList
    },
    switchToClassicUI() {
      this.$cookies.remove('force_beta')
      window.location.reload()
    },

    errorMessage(error, withTitle = false, interpolateMsg = {}) {
      let poKey = ''
      let title = this.$t('global.somethingwrong')
      let message = this.$t('global.erroroccured')

      if (typeof error === 'string') poKey = error
      else if (error instanceof Error && error.response)
        poKey = get(error.response, 'data.error', undefined)

      if (poKey && typeof poKey === 'string') {
        const { title: newTitle, message: newMessage } =
          errorPoKeys[toUpper(poKey)] || {}

        title = newTitle ? this.$t(newTitle) : title
        message = newMessage ? this.$t(newMessage) : message

        if (
          newMessage &&
          !isEmpty(interpolateMsg) &&
          poKey === 'CLOSED_OUT_OF_HOURS'
        ) {
          message = this.$t(newMessage, interpolateMsg)
        }
      }

      if (withTitle)
        return {
          title,
          message
        }

      return message
    },

    handleApiError(error, interpolateMsg = {}) {
      const ERROR_CODE = get(error, 'response.status', null)
      const errMsg = this.errorMessage(error, false, interpolateMsg)

      if ((ERROR_CODE === 403 || ERROR_CODE === 401) && !this.$auth.loggedIn) {
        this.$toast.open({ type: 'error', message: errMsg })

        this.$auth.$storage.setUniversal('redirect', this.$route.fullPath)
        window.location = this.getBlazePath('login')
      } else if (ERROR_CODE === 424) {
        this.$toast.open({
          type: 'error',
          message: this.$t('payment.errorpaymentexternal')
        })
      } else {
        this.$toast.open({ type: 'error', message: errMsg })
      }
    },

    async trackSubmitPaymentConversion(bookData, paymentData, promoCode) {
      const { currency, name, payMethod } = paymentData
      this.$tracker.trackEvent(
        `${bookData.type}_payment_submit`,
        `${bookData.type}_payment_submit`,
        `${currency}_${name}_${payMethod}`
      )
      this.trackJitsuSubmitPayment(bookData, paymentData, promoCode)
      const isFirstPayment = get(bookData, 'payments', []).length === 0
      if (isFirstPayment && bookData.type === 'flight') {
        // this.$tracker.$gtag('event', 'conversion', {
        //   send_to: `${this.$config.googleGtagConversion}/${
        //     this.$config.googleGtagConversionPurchase
        //   }`,
        //   value: 1.0,
        //   transaction_id: `${bookData.trxId}`
        // })
        window.enhanced_conversion_data = {
          email: bookData.customer.email
        }
        this.$tracker.$gtag('set', 'user_data', {
          email: bookData.customer.email
        })
        this.$tracker.$gtag('event', 'conversion', {
          send_to: `AW-706665489/UWhOCPCRjfwCEJG4-9AC`,
          value: 1.0,
          currency: paymentData.currency,
          transaction_id: `${bookData.trxId}`
        })

        //conversion bing ads
        window.uetq = window.uetq || []
        window.uetq.push('event', 'null', {
          event_label: 'submit_payment',
          event_value: 1
        })

        try {
          const affiliateList = [
            'GFS',
            'GFS+',
            'SKY1',
            'SKY1+',
            'WEGOID',
            'WEGOID+'
          ]
          if (affiliateList.includes(get(bookData, 'affiliate', ''))) {
            const purchaseItems = bookData.orders.map(order => {
              return {
                travel_class: '',
                fare_product: '',
                booking_code: order.pnr,
                flight_number: order.flightcode,
                origin: order.depAirport,
                destination: order.arrAirport,
                start_date: order.depDateTime.substr(0, 10)
              }
            })
            const adultCount = bookData.passengers.filter(
              psg =>
                psg.title.toLowerCase() !== 'infant' &&
                psg.title.toLowerCase() !== 'child'
            ).length
            const childCount = bookData.passengers.filter(
              psg => psg.title.toLowerCase() === 'child'
            ).length
            const infantCount = bookData.passengers.filter(
              psg => psg.title.toLowerCase() === 'infant'
            ).length
            this.$tracker.$gtag('event', 'purchase', {
              send_to: 'GF-846',
              trip_type: 'Other',
              value: `${paymentData.total}`,
              currency: paymentData.currency,
              passengers: {
                total: adultCount + childCount + infantCount,
                adult: adultCount,
                child: childCount,
                infant_in_seat: infantCount,
                infant_in_lap: infantCount
              },
              items: purchaseItems
            })
          } else if (this.$cookies.get('ads_traffic')) {
            const ads = this.$cookies.get('ads_traffic')
            const parsedAds = JSON.parse(getBufferString(ads))
            if (parsedAds.s == 'BINGADS') {
              window.uetq = window.uetq || []
              window.uetq.push('event', 'on_click_pay', {
                event_category: 'payment_conversion',
                event_label: 'submit_payment',
                event_value: 1,
                revenue_value: 1,
                currency
              })
            } else if (parsedAds.s === 'YHOOADSJP') {
              window.ytag({
                type: 'yss_conversion',
                config: {
                  yahoo_conversion_id: '1000417802',
                  yahoo_conversion_label: 'Wgo6CM-qqIEBENKCu4kD',
                  yahoo_conversion_value: '1'
                }
              })
            }
          }
        } catch (e) {
          this.$sentry.captureException(e)
        }
      } else if (isFirstPayment && bookData.type === 'hotel') {
        this.$tracker.$gtag('event', 'conversion', {
          send_to: `${this.$config.googleGtagConversion}/vngLCOeOkpoYEJG4-9AC`,
          value: 1.0,
          transaction_id: `${bookData.trxId}`
        })
      }
    },

    trackJitsuSubmitPayment(bookData, paymentData, promoCode) {
      try {
        const { name, method, payMethod, bank } = paymentData

        if (bookData.type === 'flight') {
          const { flights } = extractOrderFlightData(bookData)
          const { depFlight, retFlight } = Object.assign({}, flights)
          const isAfs = !!bookData.afsStatus && !!bookData.afsType
          let productName = 'flight'

          if (isAfs) {
            const afsProductNameMap = {
              addbaggage: 'afs-ssr',
              changedata: 'afs-change-data',
              changeflight: 'afs-change-flight'
            }
            const afsType = bookData.afsType.toLowerCase()
            productName = afsProductNameMap[afsType]
          }

          this.$tracker.$jitsu('payment-submit', {
            product: productName,
            screen_name: 'payment',
            rcv_code: bookData.trxId,
            dept_airport: depFlight.depAirport || '',
            trans_airport: depFlight.traAirport || '',
            arr_airport: depFlight.arrAirport || '',
            dept_date: depFlight.depDateTime || '',
            return_date: !isEmpty(flights.retFlight)
              ? retFlight.depDateTime
              : '0000-00-00 00:00:00',
            dept_airlines: depFlight.code ? depFlight.code.substring(0, 2) : '',
            pay_method: method || payMethod,
            voucher: promoCode || '',
            bank: bank || '',
            bank_options: name || '',
            total: paymentData.total,
            abandoned_cart: paymentData.abandonedCart ? 1 : 0
          })
        } else {
          this.$tracker.$jitsu('payment-submit', {
            product: 'hotel',
            screen_name: 'payment',
            rcv_code: bookData.trxId,
            pay_method: method || payMethod,
            voucher: promoCode || '',
            bank: bank || '',
            bank_options: name || '',
            total: paymentData.total,
            abandoned_cart: paymentData.abandonedCart ? 1 : 0
          })
        }
      } catch (e) {
        this.$sentry.captureException(e)
      }
    },

    setCookieChangeCurrency(currency) {
      const EXPIRY_TIME = 60 * 60 * 24 // 1 day
      this.$cookies.set(
        `Change_Currency_Payment_<${this.$route.params.id}>`,
        currency,
        {
          path: '/',
          domain: !this.$config.isDev ? '.airpaz.com' : '',
          maxAge: EXPIRY_TIME
        }
      )
    }
  }
}

Vue.mixin(functionHelper)
