import apiServices from '~/api'

const instance = {
  api: false
}

export default async (ctx, inject) => {
  // inject the api in the context (ctx.app.$api)
  const apiList = apiServices(ctx.$axios)
  // And in the Vue instances (this.$api in your components)
  // ctx.$api = api
  if (!instance.api) instance.api = apiList
  inject('api', apiList)
}

export const apiInstance = instance
