import nanoid from 'nanoid'
import LRU from 'lru-cache'
import { cacheAdapterEnhancer } from 'axios-extensions'
import get from 'lodash/get'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import { defineTrafficSource, makeid } from '@/assets/js/globalFunction.js'

const listCSRFGeneral = [
  'bind',
  'unbind',
  'login',
  'verify-signup',
  'reset-password-request',
  'change-password',
  'signout',
  'signup',
  'update',
  'contact-add',
  'contact-update',
  'contact-delete',
  'passenger-add',
  'passenger-update',
  'passenger-delete',
  'agent-deposit-payment',
  'agent-invitation',
  'agent-deposit-transfer',
  'commission-update',
  'resend-itinerary',
  'submit',
  'cancel',
  'bank-options',
  'subscribe',
  'add-ticket',
  'checkout',
  'approve'
]
const listCSRFFlight = ['add', 'delete', 'order']
const listCSRFHotel = ['order', 'cancel-order']
// Expected all asyncData request to added Here
// all link added heren will direct user to error page e.g page 404, 500
const LIST_MANDATORY_REQUEST_URLS = ['airline/info']

export default function(context) {
  const {
    $axios,
    error,
    app,
    route,
    $cookies,
    isDev,
    ssrContext,
    $config
  } = context

  const defaultCache = process.server
    ? ssrContext.$axiosCache
    : new LRU({
        max: Number($config.apiCacheMax) || 500,
        ttl: 1000 * 60 * 60 * (Number($config.apiCacheTTL) || 1),
        ttlAutopurge: true
      })

  $axios.defaults.adapter = cacheAdapterEnhancer($axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
    defaultCache
  })

  // request timeout settings
  let REQUEST_TIMEOUT = 40000
  if (process.server) REQUEST_TIMEOUT = 40000
  $axios.defaults.timeout = REQUEST_TIMEOUT
  $axios.onResponse(async response => {
    // if (
    //   response &&
    //   response.headers.http_cf_ipcountry &&
    //   response.headers.http_cf_ipcountry !== 'XX' &&
    //   response.headers.http_cf_ipcountry !== app.store.state.config.country
    // ) {
    //   app.store.commit('config/SET_COUNTRY', response.headers.http_cf_ipcountry)
    // }
    if ($cookies.get('force_country')) {
      app.store.commit('config/SET_COUNTRY', $cookies.get('force_country'))
    }
    return response
  })
  $axios.onRequest(async config => {
    const url = config.url.split('/')
    const urlRequest = url[url.length - 1].split('?')[0]

    if (process.server) {
      config.baseURL = $config.apiBaseServer
    }

    if (['/member/refresh-token', '/member/signout'].includes(config.url))
      config.baseURL = config.baseURL.replace('v1', 'v2')

    if (
      listCSRFGeneral.includes(urlRequest) ||
      listCSRFFlight.includes(urlRequest) ||
      listCSRFHotel.includes(urlRequest)
    ) {
      const result = await app.$api.getCSRF()
      if (!!result.result) {
        config.headers['x-csrf-token'] = result.result
      }
    } else delete config.headers['x-csrf-token']
    // Try Catch due to somebrowser doesn't support crypto
    try {
      // add header x-trace-id
      config.headers['x-trace-id'] = nanoid(10)
    } catch (e) {
      config.headers['x-trace-id'] = makeid(10)
    }
    // TODO dont send empty string
    // add header x-uaid
    const detectedAUID = $cookies.get('auid') || context.userAUID
    let userAUID = detectedAUID
    if (!detectedAUID) {
      const generatedAUID = uuidv4()
      userAUID = generatedAUID
      context.userAUID = generatedAUID
      $cookies.set('auid', generatedAUID, {
        path: '/',
        domain: !isDev ? '.airpaz.com' : '',
        expires: dayjs()
          .add(10, 'year')
          .toDate()
      })
    }
    config.headers['x-auid'] = userAUID || ''
    // temporary rename zh-tw to zh-TW
    if (config.params && config.params.lang && config.params.lang === 'zh-tw') {
      config.params.lang = 'zh-TW'
    } else if (config.method === 'get' && config.url.includes('zh-tw')) {
      config.url = config.url.replace('zh-tw', 'zh-TW')
    } else if (
      config.method === 'post' &&
      get(config.data, 'lang', null) === 'zh-tw'
    ) {
      config.data.lang = 'zh-TW'
    }
    // handle bookget on hotel review
    if (
      route.name &&
      route.name.includes('hotel-review-hash') &&
      config.url === '/book/get'
    ) {
      delete config.headers.Authorization
      delete config.headers.common.Authorization
    }

    // HANDLE EXTRA HEADER
    config.headers['x-source'] = defineTrafficSource(
      $cookies.get('ads_traffic'),
      $cookies.get('affiliate_params')
    )
    config.headers['x-sourcepar'] = defineTrafficSource(
      $cookies.get('ads_traffic'),
      $cookies.get('affiliate_params'),
      true
    )

    // Extra Header long & lat
    if ($cookies.get('usr_coords')) {
      config.headers['x-coords'] = $cookies.get('usr_coords')
    }

    //Add header session id & referral
    if (process.client) {
      try {
        const detectedSessionId =
          process.client && window.sessionStorage.getItem('sessionId')

        let userSessionId = detectedSessionId
        if (!detectedSessionId) {
          const generateSessionId = uuidv4()
          userSessionId = generateSessionId
          process.client &&
            window.sessionStorage.setItem('sessionId', userSessionId)
        }

        config.headers['x-session-id'] = userSessionId
        config.headers['x-ref-src'] =
          defineTrafficSource(
            window.sessionStorage.getItem('referral'),
            window.sessionStorage.getItem('affiliate_params')
          ) || 'DIRECT'
      } catch (error) {
        app.$sentry && app.$sentry.captureException(e)
      }
    }

    // add aft on header
    if (route.query.aft) {
      config.headers['x-aft'] = route.query.aft
    } else {
      delete config.headers['x-aft']
    }
    return config
  })
  $axios.onError(async e => {
    // LOG TO SENTRY IF ERROR TRIGGERED && PRODUCTION && ITS NOT CANCELLED REQUESTS
    if ($axios.isCancel(e)) {
      return Promise.reject(e)
    }

    const errorUrl = get(e, 'config.url', '')
    process.server &&
      console.log(`
        [ERROR_API] -> [${e.config.url}] => ${JSON.stringify(e)}`)
    const errorStatusCode = get(e, 'response.data.status_code', '')
    const errorResMsg = get(e, 'response.data.message', '')
    const errorCode = get(e, 'response.data.error', '')
    const errorMsg = get(e, 'message', '')
    const nonErrorResponseCodes = ['ERR_INVALID_QUERY']
    const nonErrorMessages = [
      'Network request failed',
      'Failed to fetch',
      'NetworkError',
      'Network Error',
      'Request aborted'
    ]
    const nonErrorUrlAfs = ['/afs/detail']
    const isNonErrorByMessage = nonErrorMessages.includes(errorMsg)
    const isNonErrorByResponse = nonErrorResponseCodes.includes(errorCode)
    const isNonErrorByStatus = [401].includes(errorStatusCode)
    const isBookExpired =
      errorUrl === '/fl/book/verify' &&
      errorCode === 'DATA_EXPIRED' &&
      errorResMsg === 'Book ID expired'
    const isNonErrorByUrlAfs = nonErrorUrlAfs.includes(errorUrl)
    const isNonErrorAfs = !isNonErrorByUrlAfs && isNonErrorByStatus
    // HANDLE TOKEN EXPIRED
    if (
      errorCode === 'ERROR_INTERNAL' &&
      errorResMsg === 'Failed to refresh token'
    ) {
      await app.$auth.logout()
      process.client && window.location.reload()
    }
    if (errorCode === 'TOKEN_EXPIRED') {
      if (errorUrl === '/member/signout') {
        process.client && window.location.reload()
      } else if ($cookies.get('auth._refresh_token.airpaz')) {
        await app.$auth.refreshTokens()
      } else {
        app.$auth.logout()
      }
    }

    // dont log
    if (
      app.$sentry &&
      e.code !== 'ECONNABORTED' &&
      !isNonErrorByResponse &&
      !isNonErrorByMessage &&
      !isBookExpired &&
      !isNonErrorByStatus &&
      isNonErrorAfs
    ) {
      const IMPORTANT_ERROR_CODES = [500, 502, 504, 524]
      const errorUrl = e.config?.url || ''
      const isImportantError = IMPORTANT_ERROR_CODES.includes(
        Number(errorStatusCode)
      )
      const errorPrefix = isImportantError
        ? '[CRITICAL_ERROR_API]'
        : '[ERROR_API]'
      e.name = `${errorPrefix} ${errorUrl} -> ${e.name} ${errorStatusCode}`
      app.$sentry.configureScope(scope => {
        const auid = get(e, ['config', 'headers', 'x-auid'], null)

        if (app.$auth.loggedIn) {
          scope.setUser({
            id: auid,
            email: app.$auth.user?.email
          })
        }

        scope.setExtra('AUID', auid)
        scope.setExtra('API_URL', errorUrl)
        scope.setExtra('PAGE_URL', route.fullPath)
        scope.setExtra('USER_TIME', dayjs().toString())
        scope.setExtra(
          'RESPONSE',
          JSON.stringify(get(e, 'response.data', get(e, 'response', {})))
        )
        scope.setTag('API_CALL', 'ERROR')
        scope.setTag('ERROR_LEVEL', isImportantError ? 'CRITICAL' : 'ERROR')
        // LOG TIMESTAMP
        if (
          errorUrl.includes('token') ||
          e.name.includes('ExpiredAuthSessionError')
        ) {
          const refreshTokenExpiration = $cookies.get(
            'auth._refresh_token_expiration.airpaz'
          )
          const authTokenExpiration = $cookies.get(
            'auth._token_expiration.airpaz'
          )
          scope.setExtra(
            'REFRESH_TOKEN_EXPIRATION',
            dayjs(refreshTokenExpiration).format('YYYY-MM-DD')
          )
          scope.setExtra(
            'AUTH_TOKEN_EXPIRATION',
            dayjs(authTokenExpiration).format('YYYY-MM-DD')
          )
        }

        if (Number(errorStatusCode) === 401) {
          scope.setExtra(
            'AUTH_TOKEN_EXIST',
            Boolean(
              get(
                e,
                'config.headers.authorization',
                get(e, 'config.headers.Authorization')
              )
            )
          )

          if (errorUrl === '/member/login') {
            try {
              const requestData = JSON.parse(e.config.data)
              const isSocialMediaLogin = Boolean(requestData.smId)

              e.name = `${errorPrefix} ${errorUrl} -> ${
                isSocialMediaLogin ? 'Social media login' : 'Airpaz login'
              } ${errorStatusCode}`
            } catch (err) {}
          }
        }

        app.$sentry && app.$sentry.captureException(e)
      })
    }
    const triggerError = LIST_MANDATORY_REQUEST_URLS.find(
      mandatoryUrlPattern => {
        if (e && e.request && e.request.path)
          return e.request.path.includes(mandatoryUrlPattern)
      }
    )
    triggerError && console.info('triggerError >>', triggerError)
    if (triggerError) {
      return error({
        statusCode: e.response.status,
        message: e.response.statusText
      })
    }
    throw e
  })
}
