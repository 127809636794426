import { v4 as uuidv4 } from 'uuid'

export default function() {
  if (!process.client) return
  const detectedSessionId = window.sessionStorage.getItem('sessionId')
  if (!detectedSessionId) {
    const generateSessionId = uuidv4()
    window.sessionStorage.setItem('sessionId', generateSessionId)
  }
}
