import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { isSet, isStringEqual } from '@/assets/js/globalFunction'

const defNull = o => (isSet(o) ? o : null)

export default function(ctx) {
  const { route, app, isDev, req } = ctx

  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent

  const isWeChat =
    /MicroMessenger/gi.test(userAgent) &&
    /WeChat/gi.test(userAgent) &&
    /Weixin/gi.test(userAgent)

  const wechatParams = isWeChat ? { aft: 'WECHAT' } : null
  const params = {
    ...route.query,
    ...wechatParams
  }

  const documentReferrer = process.server
    ? get(ctx, 'req.headers.referer', '')
    : document.referrer
  const mappedAds = mapAds(params, documentReferrer)
  const cookieExpired = 24 * 60 * 60 // 1day
  const mappedAft = mapAft(params)

  if (!app.$cookies.get('abandoned_cart') && params.acart) {
    const bufferAcart = Buffer.from(
      JSON.stringify({ abandonedCart: params.acart })
    ).toString('base64')

    app.$cookies.set('abandoned_cart', bufferAcart, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      maxAge: 24 * 60 * 60 //one day
    })
  }

  if (mappedAft) {
    if (mappedAft.id?.toLowerCase() === 'truemo') {
      app.$currency.set('THB', true)
    }
    const bufferAft = Buffer.from(JSON.stringify(mappedAft)).toString('base64')
    app.$cookies.set('affiliate_params', bufferAft, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      maxAge: cookieExpired
    })
  }

  if (app.$cookies.get('ads_traffic') || route.query.aft === 'GFS') {
    return
  }

  if (mappedAds) {
    // track landing page for organic traffic
    if (
      ['GOOG', 'BING', 'NAVER', 'YHOOJP', 'YHOO', 'BAIDU'].includes(mappedAds.a)
    ) {
      app.$cookies.set('organic_traffic_landing', route.path, {
        path: '/',
        domain: !isDev ? '.airpaz.com' : '',
        maxAge: cookieExpired
      })
    }

    const bufferedAds = Buffer.from(JSON.stringify(mappedAds)).toString(
      'base64'
    )

    app.$cookies.set('ads_traffic', bufferedAds, {
      path: '/',
      domain: !isDev ? '.airpaz.com' : '',
      maxAge: cookieExpired
    })
  }
}

function getRefererMap(urlSource) {
  let detectedReferrer = ''
  const referrerMap = {
    'l.facebook.com': 'FB',
    google: 'GOOG',
    'goo.gl': 'GURL',
    bing: 'BING',
    naver: 'NAVER',
    'yahoo.co.jp': 'YHOOJP',
    yahoo: 'YHOO',
    baidu: 'BAIDU',
    'ow.ly': 'HOOT',
    oemarket: 'MAIL',
    'youtube.com': 'YOUTUBE',
    'instagram.com': 'INSTAGRAM',
    'blog.airpaz': 'BLOG',
    'connect.airpaz': 'MAIL',
    '/widget/search': 'WIDGET',
    brave: 'BRAVE',
    duckduckgo: 'DUCKDUCKGO'
  }
  for (const key in referrerMap) {
    if (urlSource.includes(key)) {
      detectedReferrer = referrerMap[key]
      break
    }
  }
  return detectedReferrer
}

export function mapAds(params, referrer) {
  const adsProps = ['s', 'a', 'b', 'm', 'p', 'k', 'c', 'd', 'dm']
  let ads = {
    s: null,
    a: null,
    b: null,
    m: null,
    p: null,
    k: null,
    c: null,
    d: null,
    dm: null
  }
  if (isStringEqual(params.utm_source, 'bingads') || isSet(params.msclkid)) {
    ads = { ...ads, ...pick(params, adsProps) }
    ads.s = params.s || 'BINGADS'
  } else if (isStringEqual(params.utm_source, 'tiktokads')) {
    ads.a = 'TIKTOKADS'
  } else if (
    isStringEqual(params.source, 'YAHOO_JAPAN') ||
    isStringEqual(params.utm_source, 'yahoojpads')
  ) {
    ads.s = params.s || 'YHOOADSJP'
    ads.a = defNull(params.a) || defNull(params.campaign)
    ads.k = defNull(params.keyword)
  } else if (
    (isSet(params.gclid) && isSet(params.a)) ||
    isStringEqual(params.utm_source, 'gads')
  ) {
    ads = { ...ads, ...pick(params, adsProps) }
    ads.s = params.s || 'GADS'
    ads.u = defNull(params.u)
    ads.l = defNull(params.l)
    if (isSet(params.u)) {
      const group = /(\:)?aud-(.*?)\:/g.exec(params.u) // get the suffix of aud-
      if (isSet(group) && group[2]) ads.u = group[2]
    }
  } else if (isSet(params.cid) && isSet(params.adsid) && isSet(params.aid)) {
    ads = { ...ads, ...pick(params, adsProps) }
    ads.s = 'FBADS'
    ads.a = defNull(params.adsid)
  } else {
    if (getRefererMap(referrer)) {
      ads.a = getRefererMap(referrer)
    } else {
      ads = false
    }
  }
  if (params.surl) {
    ads = { ...ads }
    const refererSurl = getRefererMap(params.referer || '')
    ads.a = ads.a ? ads.a : refererSurl ? refererSurl : params.utm_source
    ads.surl = params.surl
  }
  return ads
}

export function mapAft(params) {
  let clickId = ''
  const { aft, ...extras } = params

  if (!aft && !params.atnct1) return

  if (!isEmpty(extras)) {
    clickId = Object.entries(extras).reduce((str, extra, idx, arr) => {
      const [key, value] = extra
      if (key.includes('id')) str += `${value}`

      return str
    }, clickId)
  }

  if (params.atnct1)
    clickId = Buffer.from(JSON.stringify(params)).toString('base64')
  const result = {
    id: !aft && params.atnct1 ? 'ACTRADE' : aft,
    clickId
  }
  if (params.surl) {
    result.surl = params.surl
  }
  return result
}
