import { isStringEqual } from '@/assets/js/globalFunction'

const listenerTypes = {
  BEFORE: 'before',
  ON: 'on'
}

class CurrencyController {
  constructor(ctx) {
    this.ctx = ctx
    this.value = ctx.store.state.config.currency

    this._beforeSwitchListeners = []
    this._onSwitchedListeners = []
  }

  set(newCurrency, preventReload = false) {
    const { store, route, app } = this.ctx
    const oldCurrency = this.value
    const { authenticationCurrency = [] } = store.state.config
    const routeName = app.getRouteBaseName(route)
    const isFullReload = authenticationCurrency.some(curr =>
      isStringEqual(curr, routeName)
    )

    if (newCurrency === oldCurrency) return

    this.value = newCurrency
    // change currency tracker
    try {
      this.ctx.app.$tracker.trackEvent(
        'general_change_currency',
        'general_change_currency',
        `${oldCurrency}_${newCurrency}`
      )
    } catch {}

    if (isFullReload) {
      this._setCookie(newCurrency)

      /**
       * currency store state will be set on store init using cookie value
       * store/index.js
       */
      if (!preventReload) {
        app.router.go()
      }
    } else {
      this._invokeSwitchListener(listenerTypes.BEFORE, oldCurrency, newCurrency)
      this._setStorage(newCurrency)
      this._invokeSwitchListener(listenerTypes.ON, oldCurrency, newCurrency)
    }
  }

  _setStorage(currency) {
    this.ctx.store.commit('config/SET_CURRENCY', currency)

    this._setCookie(currency)
  }

  _setCookie(currency) {
    this.ctx.$cookies.set('Currency', currency, {
      path: '/',
      domain: !process.env.isDev ? '.airpaz.com' : '',
      maxAge: 60 * 60 * 24 * 30 // 30 Days
    })
  }

  _invokeSwitchListener(type, oldCurrency, newCurrency) {
    const listeners =
      type === listenerTypes.ON
        ? this._onSwitchedListeners
        : this._beforeSwitchListeners

    for (const fn of listeners) {
      fn(oldCurrency, newCurrency)
    }
  }

  beforeCurrencySwitch(listener) {
    this._beforeSwitchListeners.push(listener)
  }

  onCurrencySwitched(listener) {
    this._onSwitchedListeners.push(listener)
  }

  reset() {
    this._beforeSwitchListeners = []
    this._onSwitchedListeners = []
  }
}

export default (ctx, inject) => {
  const controller = new CurrencyController(ctx)

  ctx.app.router.afterEach(() => {
    controller.reset()
  })

  inject('currency', controller)
}
