import createPersistedState from 'vuex-persistedstate'

export default ({ store, isHMR }) => {
  if (isHMR || !process.client) return

  window.onNuxtReady(() => {
    createPersistedState({
      key: 'vuex',
      paths: [
        'hotel.destinationLog',
        'hotel.searchParamsHistory',
        'flight',
        'autocomplete-suggestion',
        'book.contact',
        'book.passenger',
        'book.bookHotelContact',
        'book.bookHotelGuest',
        'book.anonContacts',
        'book.anonTravelers',
        'order',
        'selectedFlight',
        'aftersales',
        'manage.bookData',
        'mmId'
      ],
      rehydrated(store) {
        store.commit('hydration/SET_HYDRATION_STATE', false)
        store.dispatch('order/_normalizeOrdersData')
        store.dispatch('flight/_normalizeSearchHistoryData')
        store.dispatch('autocomplete-suggestion/_normalizeFlightFrequentData')
      }
    })(store)

    createPersistedState({
      key: 'shared-vuex',
      paths: ['flight-search', 'hotel-search']
    })(store)
  })
}
