export default ({ $device, $config, store, isDev, ssrContext }, inject) => {
  let airpazUrl = $device.isMobile ? $config.mAirpazUrl : $config.airpazUrl

  if (isDev) {
    const host = process.server
      ? ssrContext.req.headers.host
      : window.location.host

    airpazUrl = `http://${host}`
  }

  const urls = {
    airpazUrl,
    appstoreUrl: `https://apps.apple.com/${store.state.config.country?.toLowerCase()}/app/airpaz-cheap-flight-tickets/1066890405`,
    playstoreUrl:
      'https://play.google.com/store/apps/details?id=com.atnetwork.airpazdev'
  }
  inject('urls', urls)
}
