import Vue from 'vue'
// import upperFirst from 'lodash/upperFirst'

import ApzModal from '../components/base/ApzModal.vue'
import ApzModalCard from '../components/base/ApzModalCard.vue'
import ApzToast from '../components/base/ApzToast.vue'
import ApzDialog from '../components/base/ApzDialog.vue'
import ApzPopover from '../components/base/ApzPopover.vue'
import ApzLoading from '../components/base/ApzLoading.vue'

import { ContentLoader } from 'vue-content-loader'
// import VTooltip from 'v-tooltip'

Vue.component('ContentLoader', ContentLoader)
Vue.component('ApzLoading', ApzLoading)

// Vue.use(VTooltip)

// const requireComponent = require.context(
//   // The relative path of the components folder
//   '../components/base',
//   // Whether or not to look in subfolders
//   true,
//   // The regular expression used to match base component filenames
//   /^(?=ApzToast)|(index|Apz[A-Z]\w+)\.(vue|js)$/
// )

// requireComponent.keys().forEach(fileName => {
//   // Get component config
//   const componentConfig = requireComponent(fileName)

//   // Get PascalCase name of component
//   const componentName = upperFirst(
//     // Strip the leading `./` and extension from the filename
//     fileName.replace(/^\.\/(.*)\.\w+$/, '$1').replace(/\/index$/, '')
//   )

//   // Register component globally
//   Vue.component(
//     componentName,
//     // Look for the component options on `.default`, which will
//     // exist if the component was exported with `export default`,
//     // otherwise fall back to module's root.
//     componentConfig.default || componentConfig
//   )
// })

export default (_, inject) => {
  const ModalProgrammatic = {
    open(params) {
      if (!params) return

      let content
      let parent
      if (typeof params === 'string') content = params

      const defaultParam = {
        programmatic: true,
        ...(params.options && { ...params.options }),
        content
      }
      if (params.parent) {
        parent = params.parent
        delete params.parent
      }
      const propsData = Object.assign(defaultParam, params)

      const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
      const ModalComponent = vm.extend(params.card ? ApzModalCard : ApzModal)

      return new ModalComponent({
        parent,
        el: document.createElement('div'),
        propsData
      })
    }
  }

  const ToastProgrammatic = {
    open(params) {
      if (!params) return

      let message
      let parent
      if (typeof params === 'string') message = params

      const defaultParam = {
        message,
        queue: false,
        position: 'is-top'
      }

      if (params.parent) {
        parent = params.parent
        delete params.parent
      }

      const propsData = Object.assign(defaultParam, params)

      const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
      const ToastComponent = vm.extend(ApzToast)

      return new ToastComponent({
        parent,
        el: document.createElement('div'),
        propsData
      })
    }
  }

  const DialogProgrammatic = {
    alert(params) {
      let message

      if (typeof params === 'string') message = params

      const defaultParam = {
        canCancel: ['button', 'outside'],
        message
      }
      const propsData = Object.assign(defaultParam, params)

      const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
      const DialogComponent = vm.extend(ApzDialog)

      return new DialogComponent({
        el: document.createElement('div'),
        propsData
      })
    },
    confirm(params) {
      const defaultParam = {}
      const propsData = Object.assign(defaultParam, params)

      const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
      const DialogComponent = vm.extend(ApzDialog)

      return new DialogComponent({
        el: document.createElement('div'),
        propsData
      })
    }
  }

  const PopoverProgrammatic = {
    open(params) {
      if (!params) return

      let content
      let parent
      if (typeof params === 'string') content = params

      const defaultParam = {
        hasModalCard: true,
        animation: 'zoom-in',
        content
      }

      if (params.parent) {
        parent = params.parent
        delete params.parent
      }
      const propsData = Object.assign(defaultParam, params)

      const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
      const ModalComponent = vm.extend(ApzPopover)

      return new ModalComponent({
        parent,
        el: document.createElement('div'),
        propsData
      })
    }
  }

  // const LoadingProgrammatic = {
  //   open(params) {
  //     const defaultParam = {
  //       programmatic: true
  //     }
  //     const propsData = Object.assign(defaultParam, params)

  //     const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
  //     const LoadingComponent = vm.extend(ApzLoadingPopup)

  //     return new LoadingComponent({
  //       el: document.createElement('div'),
  //       propsData
  //     })
  //   }
  // }

  inject('modal', ModalProgrammatic)
  inject('toast', ToastProgrammatic)
  inject('dialog', DialogProgrammatic)
  inject('popover', PopoverProgrammatic)
  // inject('loader', LoadingProgrammatic)
}
