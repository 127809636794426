import isEmpty from 'lodash/isEmpty'
let loader = null
let loaderTimeout = null
export default ({ app: { i18n, $sentry }, store: { commit }, req, route }) => {
  const fetchMaster = (fileName, locale) => {
    return new Promise(async (resolve, reject) => {
      try {
        const module = await import(/* webpackChunkName: "master-[request]" */ `@airpaz/apz-js/dist/master/${locale}/${fileName}`)
        const data = module.default || module

        if (typeof data === 'object' && isEmpty(data))
          throw { code: 'EMPTY_DATA' }

        resolve(data)
      } catch (error) {
        $sentry &&
          $sentry.captureException(
            `${fileName} (${locale}) failed: ${error.code || error}`
          )

        reject(error)
      }
    })
  }

  const initMaster = async locale => {
    try {
      const masterData = [
        // { fileName: 'airline-list', mutationKey: 'INIT_AIRLINE_LIST' },
        // { fileName: 'airport-list', mutationKey: 'INIT_AIRPORT_LIST' },
        // {
        //   fileName: 'multi-airport-list',
        //   mutationKey: 'INIT_MULTI_AIRPORT_LIST'
        // }
        { fileName: 'country-list', mutationKey: 'INIT_COUNTRY_LIST' }
      ]

      if (process.server) {
        const ip = req.connection.remoteAddress || req.socket.remoteAddress
        commit('config/SET_IP', ip)
      }

      const promises = masterData.map(({ fileName, mutationKey }) => {
        return new Promise(async (resolve, reject) => {
          try {
            const data = await fetchMaster(fileName, locale)

            resolve({ mutationKey, data })
          } catch (error) {
            if (
              (error.code === 'MODULE_NOT_FOUND' ||
                error.code === 'EMPTY_DATA') &&
              locale !== i18n.defaultLocale
            ) {
              /** Fallback to using 'en' master data if current language's master data not available */
              try {
                const data = await fetchMaster(fileName, i18n.defaultLocale)

                console.log(
                  `${
                    error.code
                  } ${fileName}_${locale}, fallback to ${fileName}_${
                    i18n.defaultLocale
                  }`
                )

                resolve({ mutationKey, data })
              } catch (error) {
                reject({ mutationKey, error: error.code || error })
              }
            }

            reject({ mutationKey, error: error.code || error })
          }
        })
      })

      const master = await Promise.all(promises.map(p => p.catch(e => e)))

      if (!!master && master.length > 0)
        master.forEach(({ mutationKey, data }) =>
          commit(`master/${mutationKey}`, data || [])
        )
    } catch (error) {
      $sentry &&
        $sentry.captureException(`INIT MASTER (${locale}) failed: ${error}`)
    }
  }

  i18n.onBeforeLanguageSwitch = (_oldLocale, newLocale) => {
    initMaster(newLocale)
    commit('calendar/RESET_HOLIDAY')
    if (process.client && !loader) {
      // Display loader if network is slow
      // loaderTimeout = setTimeout(() => {
      //   loader = $loader.open()
      // }, 1000)
    }
  }

  i18n.onLanguageSwitched = () => {
    if (process.client) {
      if (loaderTimeout) {
        clearTimeout(loaderTimeout)
        loaderTimeout = null
      }

      if (loader) {
        loader.close()
        loader = null
      }
    }
  }

  initMaster(i18n.locale)
}
