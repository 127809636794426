import dayjs from 'dayjs'
import isbot from 'isbot'
import {
  defineTrafficSource,
  defineTrafficSurl,
  safeJSONParse,
  getBufferString
} from '@/assets/js/globalFunction.js'
import isEmpty from 'lodash/isEmpty'

let jitsu
let jitsuNew
const { jitsuClient } = require('@jitsu/sdk-js')

export default ({ app, isDev, $cookies, store, $config, route }, inject) => {
  let tracker = {
    $gtag(...args) {
      console.log('[gtag]', args)
    },
    fbq() {},
    $jitsu() {},
    trackEvent() {}
  }

  if (!isDev) {
    tracker = {
      $gtag: (type, category, trackData) => {
        try {
          app.$gtag(type, category, trackData)
          if (trackData.send_to === 'AW-980095828') {
            app.$gtag(type, category, { ...trackData, send_to: 'AW-706665489' })
          }
          isDev &&
            console.warn('gtag send event', {
              type,
              category,
              trackData
            })
        } catch (e) {
          isDev &&
            console.warn('gtag not enabled!', {
              type,
              category,
              trackData
            })
        }
      },
      fbq: (type, category, trackData) => {
        window.fbq && window.fbq(type, category, trackData)
      },
      $jitsu: async (event, eventPayload = {}) => {
        try {
          const adsTraffic = safeJSONParse(
            getBufferString($cookies.get('ads_traffic') || '')
          )

          const affiliateReferral = safeJSONParse(
            getBufferString(
              window.sessionStorage.getItem('affiliate_params') || ''
            )
          )

          const utmParams = {}

          Object.entries(route.query).forEach(([key, value]) => {
            if (key.startsWith('utm_')) {
              utmParams[key.replace('utm_', '')] = value
            }
          })

          const utmTrafficSession = safeJSONParse(
            getBufferString(window.sessionStorage.getItem('utm_traffic') || '')
          )
          const utmTrafficCookies = safeJSONParse(
            getBufferString($cookies.get('utm_traffic') || '')
          )
          const utmParamsSession = {}
          const utmParamsCookies = {}
          if (!isEmpty(utmTrafficSession) && isEmpty(utmParams)) {
            Object.entries(utmTrafficSession).forEach(([key, value]) => {
              const keyName = key
              utmParamsSession[key.replace('utm_', '')] = value

              if (
                keyName === 'utm_source' &&
                (value === 'fun' || value === 'funnel')
              ) {
                utmParamsSession['source'] = utmTrafficSession['utm_medium']
              }
            })
          }
          if (!isEmpty(utmTrafficCookies) && isEmpty(utmParams)) {
            Object.entries(utmTrafficCookies).forEach(([key, value]) => {
              const keyName = key
              utmParamsCookies[key.replace('utm_', '')] = value

              if (
                keyName === 'utm_source' &&
                (value === 'fun' || value === 'funnel')
              ) {
                utmParamsCookies['source'] = utmTrafficCookies['utm_medium']
              }
            })
          }

          const payload = {
            occured_at: dayjs().unix() || '0000-00-00 00:00:00',
            session_id: window.sessionStorage.getItem('sessionId'),
            auid: $cookies.get('auid'),
            country: store.state.config.country,
            fingerprint: '',
            currency: store.state.config.currency,
            language: app.i18n.locale,
            user_id: app.$auth.loggedIn ? app.$auth.user.memberId : '',
            user_email: app.$auth.loggedIn ? app.$auth.user.email : '',
            page_url: window.location.href,
            referrer: '',
            campaign_id: adsTraffic.a || '',
            campaign_ads_group: adsTraffic.b || '',
            referral_ads_parameter:
              safeJSONParse(
                getBufferString(window.sessionStorage.getItem('referral') || '')
              ) || '',
            referral_affiliate_parameter: affiliateReferral.id || '',
            traffic_source:
              defineTrafficSource(
                $cookies.get('ads_traffic'),
                $cookies.get('affiliate_params')
              ) || 'DIRECT',
            ...defineTrafficSurl(
              $cookies.get('ads_traffic'),
              $cookies.get('affiliate_params')
            ),
            source_type: '',
            user_agent: navigator.userAgent,
            platform: 'web',
            referral:
              defineTrafficSource(
                window.sessionStorage.getItem('referral'),
                window.sessionStorage.getItem('affiliate_params')
              ) || 'DIRECT',
            device_type: store.state.app.browserType,
            device_id: '',
            device_ip_longitude: '',
            device_ip_latitude: '',
            event_name: event,
            utm: !isEmpty(utmParamsCookies)
              ? { ...utmParamsCookies }
              : { ...utmParams },
            utm_session: !isEmpty(utmParamsSession)
              ? { ...utmParamsSession }
              : { ...utmParams },
            ...eventPayload
          }

          if (!isbot(window.navigator.userAgent)) {
            try {
              if (!jitsu) {
                jitsu = jitsuClient({
                  key: `${$config.jitsuToken}`,
                  tracking_host: `${$config.jitsuHost}`,
                  randomize_url: true
                })
              }
              if (!jitsuNew) {
                jitsuNew = jitsuClient({
                  key: `${$config.jitsuTokenNew}`,
                  tracking_host: `${$config.jitsuHostNew}`,
                  randomize_url: true
                })
              }
              jitsuNew.track(event, payload)
              jitsu.track(event, payload)
            } catch {}
          }

          isDev &&
            console.warn('jitsu send event', {
              event,
              payload
            })
        } catch (e) {
          app.$sentry.captureException(e)
          isDev &&
            console.warn('jitsu not enabled!', {
              event,
              eventPayload
            })
        }
      },
      trackEvent: (eventCategory, eventAction, eventLabel) => {
        try {
          window.ga('send', 'event', eventCategory, eventAction, eventLabel)
          app.$gtag('event', eventAction, {
            event_category: eventCategory,
            event_label: eventLabel,
            send_to: $config.googleGA4
          })
        } catch (e) {
          isDev &&
            console.warn('GA not enabled!', {
              eventCategory,
              eventAction,
              eventLabel
            })
        }
      }
    }
    app.router.afterEach(() => {
      try {
        app.$gtag('event', 'page_view', {
          event_category: 'page_view',
          event_label: '',
          send_to: $config.googleGA4
        })
      } catch (e) {
        isDev && console.warn('GA not enabled!')
      }
    })
  }

  inject('tracker', tracker)
}
