/* eslint-disable no-var */
export default function({ $cookies, $config, app, isDev }) {
  const loadYandex = () => {
    try {
      const isActiveCountry = app.$isToggleActive('yandex')

      if (isActiveCountry && !app.$device.isCrawler && !$config.disableYandex) {
        const el = document.getElementById('__nuxt')
        el.insertAdjacentHTML(
          'afterend',
          `<noscript><div><img src="https://mc.yandex.ru/watch/74177425" style="position:absolute; left:-9999px;" alt="" /></div></noscript>`
        )
        ;(function(m, e, t, r, i, k, a) {
          m[i] =
            m[i] ||
            function() {
              ;(m[i].a = m[i].a || []).push(arguments)
            }
          m[i].l = 1 * new Date()
          ;(k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            (k.async = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a)
        })(
          window,
          document,
          'script',
          'https://mc.yandex.ru/metrika/tag.js',
          'ym'
        )
        ym(74177425, 'init', {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true,
          params: { auid: $cookies.get('auid') }
        })
      }
    } catch (e) {
      app.$sentry.configureScope(scope => {
        scope.setTag('YANDEX', 'ERROR')
        app.$sentry.captureException(e)
      })
    }
  }

  if (!isDev) {
    loadYandex()
  }
}
