import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  observer: true,
  lazyComponent: true,
  throttleWait: 10,
  attempt: 1,
  preLoad: 1.1,
  observerOptions: { rootMargin: '250px', threshold: 0 }
})
