import isbot from 'isbot'

export default ({ store, $config }, inject) => {
  // only load on country CN
  const shouldLoadGoogleScripts = store.state.config.country !== 'CN'
  let gtag = function() {}
  window.ga =
    window.ga ||
    function() {
      ;(ga.q = ga.q || []).push(arguments)
    }
  ga.l = +new Date()
  if (
    shouldLoadGoogleScripts &&
    !isbot(window.navigator.userAgent) &&
    !window.__Cypress__
  ) {
    // GOOGLE ANALYTIC SCRIPT
    const scriptGA = document.createElement('script')
    scriptGA.defer = true
    scriptGA.id = 'googleAnalytic'
    scriptGA.src = 'https://www.google-analytics.com/analytics.js'
    document.head.appendChild(scriptGA)
    ga('create', $config.googleAnalyticsId, 'auto')

    // GTAG SCRIPT
    const scriptGtag = document.createElement('script')
    scriptGtag.async = true
    scriptGtag.id = 'gtag'
    scriptGtag.src = `https://www.googletagmanager.com/gtag/js?id=${
      $config.googleGtagConversion
    }`
    document.head.appendChild(scriptGtag)

    // Include Google gtag code and inject it (so this.$gtag works in pages/components)
    window.dataLayer = window.dataLayer || []
    gtag = function() {
      dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', $config.googleGtagConversion, {
      anonymize_ip: false, // anonymize IP
      send_page_view: false, // might be necessary to avoid duplicated page track on page reload
      linker: {
        domains: ['airpaz.com']
      },
      allow_enhanced_conversions: true
    })
  }
  inject('gtag', gtag)
}
