import { isStringEqual } from '@/assets/js/globalFunction'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import seoConfig from '@/config/initializer/config/seo-config'

export default ({ ssrContext }, inject) => {
  function i18nSeo(currentHead = {}) {
    if (!this.$store || !this.$i18n || !this.$route) return {}

    const locale = this.$i18n.locale || this.$store.state.config.locale
    const locales = this.$store.getters['config/getListLanguageObj']
    const path = this.$route.path
    const host = process.server
      ? ssrContext.req.headers.host
      : window.location.host
    const htmlAttrs = { lang: locale }
    const link = []
    const meta = []

    // link tags
    link.push(
      ...Object.entries(locales).map(([_, locale]) => {
        let newPath = path.split('/')
        newPath[1] = locale.key
        newPath = newPath.join('/')

        return {
          hid: `alternate-hreflang-${locale.hreflang}`,
          rel: 'alternate',
          href: `https://${host}${newPath}`.replace(/\/+$/, ''),
          hreflang: locale.hreflang,
          title: locale.str
        }
      })
    )

    // alternate x-default
    let newPath = path.split('/')
    newPath[1] = 'en'
    newPath = newPath.join('/')
    link.push({
      hid: 'default-alternate',
      rel: 'alternate',
      href: `https://${host}${newPath}`.replace(/\/+$/, ''),
      hreflang: 'x-default'
    })

    link.push({
      hid: `alternate-canonical`,
      rel: 'canonical',
      href: `https://www.airpaz.com${path}`.replace(/\/+$/, '')
    })

    // meta tags
    meta.push({
      hid: 'og:locale',
      property: 'og:locale',
      content: locale.replace(/-/g, '_')
    })

    // meta.push(
    //   ...Object.entries(locales).map(([_, locale]) => ({
    //     hid: 'og:locale:alternate-' + locale.key,
    //     property: 'og:locale:alternate',
    //     content: locale.key.replace(/-/g, '_')
    //   }))
    // )
    currentHead.meta = [...(currentHead.meta || []), ...meta]
    currentHead.htmlAttrs = { ...(currentHead.htmlAttrs || {}), ...htmlAttrs }
    currentHead.link = [...(currentHead.link || []), ...link]
    return currentHead
  }

  function seoSchemaBuilder(schema) {
    if (typeof schema !== 'function')
      throw new TypeError('Schema has to be a function')
    if (!this.$route) return {}

    const path = this.$route.path

    const schemaCtx = {
      url: `https://www.airpaz.com${path}`
    }

    return {
      innerHTML: JSON.stringify(schema(schemaCtx)),
      type: 'application/ld+json'
    }
  }

  function seoTagBuilder(customHead = {}) {
    const path = this.$route.path
    const { items = [] } =
      seoConfig.find(config => isStringEqual(config.url, path)) || {}

    const seoConfigMeta = []

    flatten(items).forEach(item => {
      if (item.hasOwnProperty('title')) customHead.title = item.title
      else {
        const hid = item.name || Object.values(item)[0]

        seoConfigMeta.push({
          hid,
          ...item
        })
      }
    })

    customHead.meta = [...(customHead.meta || []), ...seoConfigMeta]
    customHead.meta = uniqBy(customHead.meta.reverse(), 'hid')
    if (!customHead.titleTemplate) {
      customHead.titleTemplate = `%s - Airpaz`
    }
    return customHead
  }

  inject('i18nSeo', i18nSeo)
  inject('seoSchemaBuilder', seoSchemaBuilder)
  inject('seoTagBuilder', seoTagBuilder)
}
